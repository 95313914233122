import Vue from 'vue';
import Vuex from 'vuex';
import { getCookie } from '@/utils';
import createPersistedState from 'vuex-persistedstate';
const persistedStatePlugin = createPersistedState({
  storage: window.localStorage // 选择合适的存储类型，比如window.localStorage、window.sessionStorage等
});
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [persistedStatePlugin],
  state: {
    cartCount:"",
    msg:{}, //地址
    login: false,   // 商城是否登录
    GoodsList:[],
    showMoveImg: false, // 显示飞入图片
    elLeft: 0,
    elTop: 0,
    moveImgUrl: null,
    cartPositionT: 0, // 购物车位置
    cartPositionL: 0,
    receiveInCart: false, // 是否进入购物车
    token: null,
    userNo:null,
    themeType:'dayMode',
    tabBarHeight:0,
    user:{
      mobile:"18299999999",
      biNo:"",
      key:"",
      openId:"",
      userAgent:"",
      token:"",
    },
    custType:'',
    loginState:{
      isLogin:false,
    }
  },
  mutations: {
    SET_SHOPPINGLOGIN(state, params){
      state.login = params
    },
    SET_GOODSLIST(state, params){
      state.GoodsList = params
    },
    SET_SHOPPINGMSG(state, params){
      state.msg = params
    },
    SET_SHOPPINGCARTCOUNT(state, params){
      state.cartCount = params
    },
    SET_TOKEN(state, params) {
      state.user.token = params;
    },
    SET_CUSTTYPE(state, params) {
      state.custType = params.custType;
      
    },
    SET_THEMETYPE(state, themeType) {
      state.themeType = themeType;
    },
    SET_TabBARHEIGHT(state, h) {
      state.tabBarHeight = h;
    },
    SET_LOGINSTATE(state, loginStatus) {
      let loginState = { ...state.loginState }
      state.loginState = { ...loginState, ...loginStatus }
    },
    SAVE_USER(state,params){
      state.user={...state.user,...params}
    },
    // 加入购物车动画
    ADD_ANIMATION(state, {moveShow, elLeft, elTop, img, cartPositionT, cartPositionL, receiveInCart}){
      state.showMoveImg = moveShow
      if (elLeft) {
        state.elLeft = elLeft
        state.elTop = elTop
      }
      state.moveImgUrl = img
      state.receiveInCart = receiveInCart
      if (cartPositionT) {
        state.cartPositionT = cartPositionT
        state.cartPositionL = cartPositionL
      }
    }
    

  },
  actions: {
    setShoppingCartCount({ commit }, params) {
      commit('SET_SHOPPINGCARTCOUNT',params );
    },
    setShoppingMsg({ commit }, params) {
      commit('SET_SHOPPINGMSG',params );
    },
    setGoodsList({ commit }, params) {
      commit('SET_ADDGOODSLIST',params );
    },
    setShoppingLogin({ commit }, params) {
      commit('SET_SHOPPINGLOGIN',params );
    },
    setToken({ commit }, params) {
      commit('SET_TOKEN',params );
    },
    setCustType({ commit }, params) {
     
      commit('SET_CUSTTYPE',params );
    },
    setThemeType({ commit }, themeType) {
      commit('SET_THEMETYPE', themeType);
    },
    setTabBarHeight({ commit },h){
      commit('SET_TabBARHEIGHT', h);
    },
    setLoginState({ commit }, state) {// 登录状态
      commit('SET_LOGINSTATE', state);
    },
    saveUser({ commit }, params){
      commit('SAVE_USER', params);
    }
  },
  getters:{
    user:state=>state.user,
    msg:state=>state.msg,
    login:state=>state.login,
    GoodsList:state=>state.GoodsList,
    isLogin(state) {
      if (state.loginState.isLogin) {
        return true;
      } else {
        return false;
      }
    },
    getShoppingLogin(state) {
      if (state.login) {
        return true;
      } else {
        return false;
      }
    },
    getToken:state=>{
      return state.token;
    },
    getCustType:state=>{
      return state.custType;
    }
    
  }

})